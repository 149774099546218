<template>
  <div class="home">
    <Header class="headerTop"/>
    <div class="header">
      <div class="header-bg"/>
    </div>
    <introduceContent/>
    <introduceContent2/>
    <Footer/>
  </div>
</template>

<script>
import Header from '../../components/Header/bigScreenHeader.vue'
import Footer from '../../components/Footer/Footer.vue'
import introduceContent from '../../components/AboutUs/introduceContent.vue'
import introduceContent2 from '../../components/AboutUs/introduceContent2.vue'
export default {
  components:{Footer,introduceContent,introduceContent2,Header},
  data() {
    return {
    }
  },

  methods: {
    open(val){
      if(val=='b'){
        window.open('http://gccloud.com/','_blank')
      }else if(val=='a'){
        this.$router.push({ path: '/introduce' })
      }
    },
    jumpTo(val){
      if(val==='online'){
        window.open('https://www.yuque.com/chuinixiongkou/bigscreen/index','_blank')
      }else if(val==='question'){
        window.open('https://www.yuque.com/chuinixiongkou/bigscreen/qa','_blank')
      }else if(val==='display'){
        window.open('http://gcpaas.gccloud.com/bigScreen','_blank')
      }else if(val==='download'){
        window.open('https://github.com/gcpaas','_blank')
      }else if(val==='home'){
        this.$router.push({ path: '/' })
      }
    },
    //登录
  },
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  background: #0C0D21;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-width: 1300px;
  .headerTop{
    position: fixed;
    top: 0;
  }
  .header {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #0C0D21;
    background-size: 100%;
    position: relative;
    min-height: 600px;
    // z-index: 99;
    &-bg{
      background-size: 100% !important;
      background: url('~@/assets/img/bg3.png') no-repeat;
      margin-top: -100px;
      min-height:800px;
    }
  }
}

/deep/.el-dropdown{
  color: #ffffffd2;
  font-size: 16px;
  cursor: pointer;
  &:hover{
  color: #FFFFFF;
  }
}

</style>
