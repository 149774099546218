<template>
  <div class="content">
      <div class="content-title">
        关于我们
      </div>
      <div class="content-text">
       <span style="display: inline-block;width: 44px"></span>GCPAAS是一款开源产品，定位通过业务组件化来解决现实存在的问题，属于科⼤国创云⽹科技有限公司创+研究院下设生态平台PBU，由千行运营团队参负责运营，我们是⼀群激情满满的开发者和贡献者，致⼒于为开源社区创造更好的解决⽅案。 我们的⽬标是在尊重开放性、可持续性和透明度的前提下，为⽤户提供最佳体验。通过我们的产品和服务，我们希望能够改善⼈们的数字⽣活，并促进技术的创新和普及。我们的团队成员具有⼴泛的技术背景和领域专业知识，我们在软件⼯程、设计、安全等领域拥有丰富的经验。我们对开源社区充满热情，也⾮常感谢社区中其他开发者和贡献者的⽀持和合作。 如果您对我们的产品或团队感兴趣，欢迎联系我们。我们期待着与您共同为开源社区做出贡献。
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
   background: url('~@/assets/img/bolangBg.png') no-repeat;
   margin-top: -500px;
   z-index: 1;
   flex-direction: column;
   padding: 100px 15vw 0 15vw;
   background-size: cover;
   align-items: center;
   &-title{
    color: #FFFFFF;
    font-weight: bolder;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 0px;
    margin-top: 40px;
   }
   &-text{
    margin-top:50px ;
    margin-bottom: 80px;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 1.8;
    letter-spacing: 0px;
   }
}
</style>
