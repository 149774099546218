<template>
  <div class="content">
    <div class="content-title">
      联系我们
    </div>
    <div class="content-text">
      <span style="display: inline-block;width: 44px"></span>如果您在使⽤我们的产品中遇到任何问题或有建议和意⻅想要分享，请随时联系我们！您可以通过以下⽅式与我们取得联系。我们珍视每位⽤户的声⾳，感谢您对我们的产品和团队的⽀持。如果您有任何问题或需要帮助，请不要犹豫，联系我们！我们会尽⼒为您提供最好的服务。  
    </div>
    <div class="content-list">
      <div class="content-list-item">
        <img src="../../assets/img/icon1.png"  class="content-list-item_img"/>
        <div  class="content-list-item_title">反馈表单</div>
        <div  class="content-list-item_url"><a href="https://www.wjx.cn/vm/woY3Nga.aspx" style="color:#709AFF" target="_blank">https://www.wjx.cn</a></div>
        <div  class="content-list-item_text">在⽹站上提交反馈表单我们将尽快回复您的消息</div>
      </div>
      <div class="content-list-item">
        <img src="../../assets/img/icon2.png"  class="content-list-item_img"/>
        <div  class="content-list-item_title">电子邮箱</div>
        <div  class="content-list-item_url">tech@ustcinfo.com</div>
        <div  class="content-list-item_text">发送电⼦邮件⾄我们的团队邮箱我们会尽快回复您</div>
      </div>
      <div class="content-list-item">
        <img src="../../assets/img/icon3.png"  class="content-list-item_img"/>
        <div  class="content-list-item_title">社交媒体</div>
        <div  class="content-list-item_url">公众号</div>
        <div  class="content-list-item_text">在社交媒体上跟踪我们的更新和动态我们会不定期发布各种新闻和公告</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content {
   display: flex;
  //  z-index: 99;
   flex-direction: column;
   padding: 100px 15vw 0 15vw;
   background-size: cover;
   align-items: center;
   &-title{
    color: #FFFFFF;
    font-weight: bolder;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 0px;
   }
   &-text{
    margin-top:50px ;
    margin-bottom: 80px;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 1.8;
    letter-spacing: 0px;
   }
   &-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-item{
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background: #1E1F31;
      align-items: center;
      width: 31%;
      &_img{
        width: 92px;
        height: 92px;
        margin-top: 48px;
      }
      &_title{
        margin-top: 29px;
        color: #FFFFFF;
        font-size: 24px;
        line-height: normal;
        letter-spacing: 0px;
      }
      &_url{
        margin-top: 16px;
        color: #709AFF;
        font-size: 24px;
        line-height: normal;
        letter-spacing: 0px;
        text-decoration: underline;
      }
      &_text{
        margin-top: 32px;
        color: #EEEEEE;
        font-size: 18px;
        line-height: 1.8;
        letter-spacing: 0px;
        text-align: center;
        width: 75%;
        margin-bottom: 59px;
       
      }
    }
     
    }
}
</style>
